/*! purgecss start ignore */

@import './_imports/fontface.css';

@tailwind  base;
@tailwind  components;

@import './_imports/globals.css';

@import './_imports/gradient-shadow.css';

@import './_imports/nav.css';

@import './_imports/button.css';

@import './_imports/gradient-animated.css';

/* FW CTA */

.cta-overlay {
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.cta-overlay.zoom {
  opacity: .5;
}

/* Hero Video */

.video-background {
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 450%; left: -175%; }
}

/* Entrance Animations */

.watch-fade {
  transition: opacity 1s, transform 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.watch-fade.outView {
  opacity: 0;
  transform: translateY(4rem);
}

.watch-fade.inView {
  transform: translateY(0rem);
}

.card-3col.watch-fade:nth-of-type(2) {
  transition-delay: .175s;
}

.card-3col.watch-fade:nth-of-type(3) {
  transition-delay: .35s;
}


/*! purgecss end ignore */  

@tailwind  utilities;

@responsive {
  .bg-gradient-brand {
    background: rgb(0,85,203);
    background: linear-gradient(90deg, rgba(0,85,203,1) 0%, rgba(1,212,237,1) 50%, rgba(120,217,17,1) 100%);
  }

  .bg-gradient-brand-dark {
    background: rgb(0,85,203);
    background: linear-gradient(135deg, rgba(0,85,203,1) 0%, rgba(0,111,249,1) 50%, rgba(1,196,237,1) 100%);
  }

  .bg-gradient-brand-light {
    background: rgb(0,144,255);
    background: linear-gradient(90deg, rgba(0,144,255,1) 0%, rgba(41,200,234,1) 50%, rgba(38,232,215,1) 100%);
  }

  .bg-gradient-electricity {
    background: rgb(27,237,122);
    background: linear-gradient(135deg, rgba(27,237,122,1) 0%, rgba(1,212,237,1) 100%);
  }

  .bg-gradient-agriculture {
    background: rgb(73,193,255);
    background: linear-gradient(135deg, rgba(73,193,255,1) 0%, rgba(139,54,255,1) 100%);
  }

  .bg-gradient-manufacturing {
    background: rgb(237,89,255);
    background: linear-gradient(135deg, rgba(237,89,255,1) 0%, rgba(163,42,255,1) 100%);
  }

  .bg-gradient-transportation {
    background: rgb(237,89,255);
    background: linear-gradient(135deg, rgba(237,89,255,1) 0%, rgba(255,50,112,1) 100%);
  }

  .bg-gradient-buildings {
    background: rgb(255,133,99);
    background: linear-gradient(135deg, rgba(255,133,99,1) 0%, rgba(255,54,54,1) 100%);
  }

  .bg-gradient-blue-fade {
    background: linear-gradient(90deg, rgba(30,64,202,1) 20%, rgba(30,64,202,0) 100%);
  }
}
