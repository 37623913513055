/*! purgecss start ignore */

/* Nav background */

main nav.scroll {
  @apply bg-opacity-100 shadow;
}

/* General Nav Styles */

main nav a.logo-wrap .be-logo {
  @apply w-64 transition-opacity duration-300;
}

main nav a.logo-wrap:hover .be-logo {
  @apply opacity-75;
}

main nav.scroll a.logo-wrap #logotype .path {
  @apply text-tint-black-900;
}

main nav.scroll a.logo-wrap:hover #logotype .path {
  @apply text-tint-black-700;
}

main nav a.logo-wrap #logomark .back {
  fill:url(#SVGID_1_);
}

main nav a.logo-wrap #logomark .front {
  fill:url(#SVGID_2_);
}

main nav .grid-toggle .grid-toggle-check {
  @apply w-3 h-3 bg-white border-2 border-white rounded-full mr-2 transition-colors duration-500;
  box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
}

main nav .grid-toggle:hover .grid-toggle-check {
  @apply bg-tint-white-500;
}

main nav.scroll .grid-toggle:hover .grid-toggle-check,
main.hero-light nav .grid-toggle:hover .grid-toggle-check {
  @apply bg-blue-400 bg-opacity-50;
}

main nav .grid-toggle.checked .grid-toggle-check {
  @apply bg-blue-400;
}

main nav.scroll .grid-toggle .grid-toggle-text {
  @apply text-tint-black-900;
}

main nav.scroll .grid-toggle:hover .grid-toggle-text {
  @apply text-blue-400;
}

/* Nav styles for dark background heroes */

main nav a.logo-wrap #logotype .path {
  @apply fill-current text-white transition-colors duration-300;
}

main nav a.logo-wrap:hover #logotype .path {
  @apply text-tint-white-900;
}

main nav .grid-toggle .grid-toggle-text {
  @apply font-bodyMed text-sm text-white select-none mt-1 transition-colors duration-500;
}

main nav .grid-toggle:hover .grid-toggle-text {
  @apply text-tint-white-700;
}

/* Nav styles for light background heroes */

main.hero-light nav a.logo-wrap #logotype .path {
  @apply fill-current text-tint-black-900 transition-colors duration-300;
}

main.hero-light nav a.logo-wrap:hover #logotype .path {
  @apply text-tint-black-700;
}

main.hero-light nav .grid-toggle .grid-toggle-text {
  @apply font-bodyMed text-sm text-tint-black-900 select-none mt-1 transition-colors duration-500;
}

main.hero-light nav .grid-toggle:hover .grid-toggle-text {
  @apply text-blue-400;
}










/*! purgecss end ignore */