html {
  scroll-behavior: smooth;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
}

.section-sub {
  @apply w-full font-bodyMed text-xs tracking-wider uppercase mb-12;
}