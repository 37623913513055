@font-face {
  font-family: "Korolev";
  src: url("../../fonts/KorolevCompressed-Bold.woff2") format("woff2"),
       url("../../fonts/KorolevCompressed-Bold.woff") format("woff");
}

@font-face {
  font-family: "Rational Book";
  font-feature-settings: "ss01", "ss05", "ss06", "ss08";
  src: url("../../fonts/rational-textbook.woff2") format("woff2");
}

@font-face {
  font-family: "Rational Med";
  font-feature-settings: "ss01", "ss05", "ss06", "ss08";
  src: url("../../fonts/rational-textmedium.woff2") format("woff2");
}

@font-face {
  font-family: "Rational Bold";
  font-feature-settings: "ss05", "ss08";
  src: url("../../fonts/rational-displaysemibold.woff2") format("woff2");
}