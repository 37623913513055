.button-wrapper {
  @apply z-10;
}

.button {
  @apply relative flex items-center p-2 pl-6 rounded-full transition-all duration-500;
}

.button:hover {
  @apply shadow-lg;
}

.button p {
  @apply font-bodyBold text-lg leading-snuger mr-4;
}

.button .arrows-wrap {
  @apply border-1 rounded-full p-3 overflow-hidden transition-all duration-500;
}

.button .arrows-cont {
  @apply relative transition-transform duration-500 ease-in-out transform;
}

.button .arrow {
  @apply w-4;
}

.button .arrow.left {
  @apply absolute top-0 transform -translate-x-12;
}