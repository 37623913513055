/*! purgecss start ignore */

.gradient-wrap {
  @apply relative overflow-hidden;
  background: rgb(41, 200, 234);
  background: linear-gradient(
    180deg,
    rgba(0, 144, 255, 1) 0%,
    rgba(41, 200, 234, 1) 50%,
    rgba(42, 200, 186, 1) 100%
  );
  min-height: 60vh;
}

.gradient-ani {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(41, 200, 234);
  background: linear-gradient(
    60deg,
    rgba(0, 144, 255, 1) 0%,
    rgba(41, 200, 234, 1) 50%,
    rgba(42, 200, 186, 1) 100%
  );
  animation: gradient-animate-1 5s infinite alternate;
}

.gradient-ani:nth-of-type(2) {
  background: rgb(41, 200, 234);
  background: linear-gradient(
    270deg,
    rgba(0, 144, 255, 1) 0%,
    rgba(41, 200, 234, 1) 50%,
    rgba(42, 200, 186, 1) 100%
  );
  animation: gradient-animate-1 4s infinite alternate-reverse;
}

@keyframes gradient-animate-1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logo-super {
  @apply block absolute bottom-0 right-0 w-full max-w-3xl max-h-full;
}

.logo-super .mark-crop {
  @apply fill-current text-white opacity-25;
  animation: super-rotate 100s linear infinite;
  transform-origin: 100% 100%;
}

@keyframes super-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/*! purgecss end ignore */
